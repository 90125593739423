<template>
    <div
        v-loading="loading"
        element-loading-text="数据较多，拼命加载中..." 
        class="incomeCalculation-container common-padding">
        <div class="title">
            收入测算
            <el-button class="common-screen-btn" type="primary" @click="() => { download() }">批量导出</el-button>
        </div>
        <div class="calculation-screen-container">
            <div class="common-input-container">
                <span>收入月份:</span>
                <el-date-picker v-model="params.startDate" type="month" class="common-screen-input" value-format="yyyy-MM-dd" placeholder="收入月份" @change="changeDate"></el-date-picker>
            </div>
            <div class="common-input-container">
                <span>所属部门:</span>
                <a-cascader class="common-screen-input" :showSearch="true" v-model="params.userDepartId" :options="selectDepart" :fieldNames="{ label: 'name', value: 'id', children: 'child' }" placeholder="请选择" change-on-select @change="changeDate" />
            </div>
            <div class="common-input-container">
                <span>客服名称:</span>
                <el-input class="common-screen-input" v-model="params.name" placeholder="客服名称" clearable></el-input>
            </div>
            <el-button class="common-screen-btn" type="primary" @click="() => changeDate()" >查 询</el-button>
            <el-button class="common-screen-btn" plain @click="() => resetBtn()">重 置</el-button>
        </div>
        <div class="grey-line"></div>
        <div style="padding: 32px 16px 0">
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column fixed prop="nickName" label="客服名称" width="120">
                    <template slot-scope="scope">
                        <span style="color: #1890ff;cursor: pointer;" @click="() => { $router.push(`/personal-center?userId=${scope.row.adminId}&startDate=${params.startDate}&endDate=${params.endDate}`); }">{{scope.row.nickName || '--'}}</span>
                    </template>
                </el-table-column>
                <el-table-column :formatter="tableColumn" prop="serviceLevel" label="岗位级别" width="140"></el-table-column>
                <el-table-column :formatter="tableColumn" prop="basePay" label="当前底薪（元）" width="140"></el-table-column>
                <el-table-column fixed :formatter="tableColumn" label="收入测算（元）" width="140">
                    <template slot-scope="scope">
                        <div style="color:#EE8E08">{{(Number(scope.row.basePay || 0) + Number(scope.row.achievements || 0) + Number(scope.row.deductMoney || 0) + Number(scope.row.bountyMoney || 0) + (scope.row.subsidyMoney || 0)).toFixed(2)}}</div>
                    </template>
                </el-table-column>
                <el-table-column :formatter="tableColumn" prop="agreementInquiries" label="协议咨询量（个）" width="160"></el-table-column>
                <el-table-column :formatter="tableColumn" prop="actualReceptionNum" label="实际咨询量（个）" width="160"></el-table-column>
                <el-table-column :formatter="tableColumn" prop="performanceCompletionRate" label="绩效完成率" width="140">
                    <template slot-scope="scope">
                        <span>{{scope.row.performanceCompletionRate}}</span>
                    </template>
                </el-table-column>
                <el-table-column
          :formatter="tableColumn"
          prop="monthCap"
          label="个人产值（元）"
          width="140"
        >
        <template slot-scope="scope">
          <span
              style="color: #1890ff;cursor: pointer;"
              @click="
                () => {
                  openDialog(scope.row)
                }"
              >{{ scope.row.monthCap || "--" }}</span
            >
        </template>
        </el-table-column>
                <el-table-column :formatter="tableColumn" prop="basicSalary" label="基础提成（元）" width="140"></el-table-column>
                <el-table-column :formatter="tableColumn" prop="advancedPrice" label="超临界值提成（元）" width="180"></el-table-column>
                <el-table-column :formatter="tableColumn" prop="achievements" label="总绩效提成（元）" width="160"></el-table-column>
                <el-table-column :formatter="tableColumn" prop="attendance" label="出勤率" width="140">
                    <template slot-scope="scope">
                        <span>{{scope.row.attendance}}</span>
                    </template>
                </el-table-column>
                <el-table-column :formatter="tableColumn" prop="deductMoney" label="异常扣款（元）" width="140"></el-table-column>
                <el-table-column :formatter="tableColumn" prop="bountyMoney" label="奖励（元）" width="140"></el-table-column>
                <el-table-column :formatter="tableColumn" prop="subsidyMoney" label="补贴（元）" width="140"></el-table-column>
                
            </el-table>
            <br/>
            <el-pagination
            @size-change="(e) => pageFun(e, 'pageSize')"
            @current-change="(e) => pageFun(e)"
            style="text-align: center"
            :page-sizes="pagination.pageSizes"
            :page-size="params.pageSize"
            :current-page="params.page"
            layout="total, prev, pager, next,sizes, jumper"
            :total="pagination.total">
            </el-pagination>
        </div>

        <!-- 产值弹 -->
    <el-dialog
      v-dialogDrag
      title="个人月产值明细"
      :visible.sync="dialogVisible"
      width="50%"
      append-to-body
      :before-close="handleClose">
      <el-table
      v-loading="loading2"
      element-loading-text="数据较多，拼命加载中..."
      :data="dialogData.records"
      style="width: 100%">
        <el-table-column
          prop="checkDate"
          :formatter="tableColumn"
          label="交班日期">
        </el-table-column>
        <el-table-column
          prop="shopNum"
          :formatter="tableColumn"
          label="服务店铺数量（个）">
        </el-table-column>
        <el-table-column
          prop="subNum"
          :formatter="tableColumn"
          label="子账号数量（个）">
        </el-table-column>
        <el-table-column
          prop="receptNum"
          :formatter="tableColumn"
          label="交班咨询量（个）">
        </el-table-column>
        <el-table-column
          label="产能（元）">
          <template slot-scope="scope">
            <el-popover
              placement="left"
              title="个人日产值明细"
              width="auto"
              trigger="click"
              @show="showDay(scope.row.shiftId)"
              @hide="clearDayParams"
            >
            <el-button slot="reference" type="text">{{ scope.row.capacity || "--" }}</el-button>
            
            <el-table
              v-loading="loading3"
              border
              element-loading-text="数据较多，拼命加载中..."
              :data="dayData"
              class="table3">
                <el-table-column
                  prop="shopName"
                  show-overflow-tooltip
                  :formatter="tableColumn"
                  label="店铺名称">
                </el-table-column>
                <el-table-column
                  prop="subaccountName"
                  show-overflow-tooltip
                  :formatter="tableColumn"
                  label="子账号名称">
                </el-table-column>
                <el-table-column
                  prop="dayCapacity"
                  show-overflow-tooltip
                  :formatter="tableColumn"
                  label="子账号单日产能">
                  <template slot="header" slot-scope="{ column }">    
                    <el-tooltip class="item" effect="dark" content="子账号单日产能：店铺金额/分配子账号天数/单日店铺分配子账号数" placement="bottom">                
                    <div>
                      <span>{{column.label}}</span>
                    <img
                      src="../../assets/images/question.png"
                      class="tit-img"
                    />
                    </div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="subCapacity"
                  show-overflow-tooltip
                  :formatter="tableColumn"
                  label="子账号总产能">
                  <template slot="header" slot-scope="{ column }">    
                    <el-tooltip class="item" effect="dark" content="子账号总产能：店铺金额/分配子账号天数*客服带号子账号天数" placement="bottom">                
                    <div>
                      <span>{{column.label}}</span>
                    <img
                      src="../../assets/images/question.png"
                      class="tit-img"
                    />
                    </div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="actualReceptionNum"
                  show-overflow-tooltip
                  :formatter="tableColumn"
                  label="交班咨询量">
                  <template slot="header" slot-scope="{ column }">    
                    <el-tooltip class="item" effect="dark" content="交班咨询量：当月客服交班填写的实际咨询量累计" placement="bottom">                
                    <div>
                      <span>{{column.label}}</span>
                    <img
                      src="../../assets/images/question.png"
                      class="tit-img"
                    />
                    </div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column
                  :formatter="tableColumn"
                  show-overflow-tooltip
                  label="子账号服务时间">
                  <template slot="header" slot-scope="{ column }">    
                    <el-tooltip class="item" effect="dark" content="当月该子账号的服务时段" placement="bottom">                
                    <div>
                      <span>{{column.label}}</span>
                    <img
                      src="../../assets/images/question.png"
                      class="tit-img"
                    />
                    </div>
                    </el-tooltip>
                  </template>
                  <template slot-scope="scope">
                    <span>{{ (scope.row.startTime? scope.row.startTime:'--') + '\n' + (scope.row.endTime? scope.row.endTime:'--')}}</span>
                
                  </template>
                </el-table-column>
            </el-table>
            <!-- <table v-loading="loading3"
    element-loading-text="数据较多，拼命加载中..." class="hover-table">
                <tr>
                  <th class="hover-th">店铺名称</th>
                  <th class="hover-th">子账号名称</th>
                  <th class="hover-th">
                    <el-tooltip class="item" effect="dark" content="子账号单日产能：店铺金额/分配子账号天数/单日店铺分配子账号数" placement="bottom">
                    <div>子账号单日产能<img
                      src="../../assets/images/question.png"
                      class="tit-img"
                      /></div>
                    </el-tooltip>
                  </th>
                  <th class="hover-th">子账号总产能</th>
                  <th class="hover-th">交班咨询量</th>
                  <th class="hover-th">子账号服务时间</th>
                </tr>
                <tr
                  v-for="(item, index) in dayData"
                  :key="index"
                >
                  <el-tooltip class="item" effect="dark" :content="item.shopName" placement="bottom">
                  <td class="hover-td">{{ item.shopName||"--" }}</td>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" :content="item.subaccountName" placement="bottom">
                  <td class="hover-td">{{ item.subaccountName||"--" }}</td>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" :content="item.dayCapacity+''" placement="bottom">
                  <td class="hover-td">{{ item.dayCapacity||"--" }}</td>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" :content="item.subCapacity" placement="bottom">
                  <td class="hover-td">{{ item.subCapacity||"--" }}</td>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" :content="item.actualReceptionNum" placement="bottom">
                  <td class="hover-td">{{ item.actualReceptionNum||"--" }}</td>
                  </el-tooltip>
                  <td class="hover-td">{{ (item.startTime? item.startTime:'--') + '\n' + (item.endTime? item.endTime:'--')}}</td>
                </tr>
                <tr
                  v-if="
                    !dayData ||
                      !dayData.length
                  "
                >
                  <td colspan="6" style="text-align:center" class="hover-td">
                    暂无数据
                  </td>
                </tr>
              </table> -->
              <br />
              <el-pagination
                @size-change="(e) => pageFun3(e, 'pageSize')"
                @current-change="(e) => pageFun3(e)"
                style="text-align: center"
                :page-sizes="pagination3.pageSizes"
                :page-size="dayParams.pageSize"
                :current-page="dayParams.page"
                layout="total, prev, pager, next,sizes, jumper"
                :total="pagination3.total"
              >
              </el-pagination>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <br />
      <el-pagination
        @size-change="(e) => pageFun2(e, 'pageSize')"
        @current-change="(e) => pageFun2(e)"
        style="text-align: center"
        :page-sizes="pagination2.pageSizes"
        :page-size="dialogParams.pageSize"
        :current-page="dialogParams.page"
        layout="total, prev, pager, next,sizes, jumper"
        :total="pagination2.total"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    </div>
</template>
<script>
import { incomeCalList, capacityDetail,capacityDayDetail } from "../../service/portrait.js"
import { commonDepartAll } from "../../service/common.js"
import { Config, getSectionDate, tableColumn } from "../../utils/index.js"
import { Cascader } from 'ant-design-vue';
import { mapState } from "vuex";
export default {
    components: { aCascader: Cascader },
    data() {
        return {
            tableColumn,
            loading: false,
            loading2: false,
            loading3: false,
            userId: this.$sto.get(Config.constants.userInfo).user_id, // 当前登陆人
            companyId: this.$sto.get(Config.constants.userInfo).company_id, // 公司id
            tableData: [], // 列表数据
            selectDepart: [], // 所属部门下拉
            params: {
                page: 1,
                pageSize: 10,
                startDate: '',
                endDate: '',
            },
            pagination: {
                total: 0,
                pageSizes: [5, 10, 20, 50, 100],
            },
            dialogVisible: false,
            dialogParams: {
              page: 1,
              pageSize: 10
            },
            pagination2: {
              total: 0,
              pageSizes: [5, 10, 20, 50, 100]
            },
            dialogData: {},
            dayParams: {
              page: 1,
              pageSize: 10
            },
            pagination3: {
              total: 0,
              pageSizes: [5, 10, 20, 50, 100]
            },
            dayData: [],
            tooltipArr: [
              '',
              '岗位级别在【组织-客服等级设置】中设置，在【客服-客服列表】中对客服进行等级设置或批量设置。',
              '底薪与岗位级别对应，在【组织-客服等级设置】中进行设置。',
              '收入=底薪+基础提成+超临界值提成-异常扣款+奖励+补贴',
              '当前所选月份中，客服所带店铺的协议咨询量总和',
              '当前所选月份中，客服交班时提交的实际咨询量总和',
              '绩效完成率=实际咨询量÷协议咨询量',
              '【说明1】个人产值=当前月份客服单日个人产值累计\n【说明2】客服单日个人产值=客服子账号单日产值累计\n【说明3】客服子账号单日产值=店铺金额/分配子账号天数/单日店铺分配子账号数',
              '在【员工画像-绩效设置】中进行设置',
              '在【员工画像-绩效设置】中进行设置',
              '总绩效提成=基础提成+超临界值提成',
              '客服使用桌面端交接班的完成率',
              '在【员工画像-工资项】中进置与查看扣款记录，分为系统自动计算和手动添加计算',
              '​在【员工画像-工资项】的「手动添加计算」页签中设置',
              '在【员工画像-工资项】的「手动添加计算」页签中设置',
            ],
            pageAgain: false,
            changePage: false, //手动改变分页
        }
    },
    computed: {
        ...mapState(["comId"])
    },
    created () {
        this.getComId()
    },
    methods: {
        getComId() { // 获取comID&&请求列表
            setTimeout(() => {
                this.companyId = this.comId
                this.params = {
                    page: 1,
                    pageSize: 10,
                    startDate: getSectionDate('month')[0],
                    endDate: getSectionDate('month')[1],
                }
                this.incomeCalList()
                this.commonDepartAll(this.comId);
            },400)
        },
        download() { // 导出收入测算
            let params = { ...this.params };
            params.companyId = this.companyId
            params.type = 1
            if (params.userDepartId && params.userDepartId.length) {
                params.userDepartId = params.userDepartId[params.userDepartId.length -1]
            }
            delete params.page;
            delete params.pageSize;
            let url = Config.api.url + "/ums/umsServiceLevel/download";
            let paramArr = [];
            for (let item in params) {
                if (params[item]) {
                paramArr.push(item + "=" + params[item]);
                }
            }
            url = url + "?" + paramArr.join("&");
            window.location.href = url;
        },
        resetBtn () {
            this.params = {
                page: 1,
                pageSize: 10,
                startDate: getSectionDate('month')[0],
                endDate: getSectionDate('month')[1],
            }
            this.incomeCalList()
        },
        changeDate () {
            let params = {...this.params}
            this.params = Object.assign(params, { ...this.defaultDate(params.startDate), page: 1, pageSize: 10 })
            this.incomeCalList()
        },
        defaultDate(date) { // 月份筛选
            let nowDate = new Date(date)
            let year = nowDate.getFullYear()
            let month = nowDate.getMonth() + 1
            let dateAfter = new Date(year, month, 0).getDate();
            return {startDate: `${year}-${month < 10 ? '0' + month : month}-01`, endDate: `${year}-${month < 10 ? '0' + month : month}-${dateAfter}`}
        },
        async incomeCalList () {
            let params = {...this.params}
            // params.adminId = this.userId
            params.companyId = this.companyId
            params.type = 1
            if (params.userDepartId && params.userDepartId.length) {
                params.userDepartId = params.userDepartId[params.userDepartId.length -1]
            }
            this.loading = true
            let { data } = await incomeCalList(params)
            this.loading = false
            this.tableData = data.allList
            this.pagination.total = data.total
        },
        commonFun() {
            this.params.page = 1;
            this.params.pageSize = 10;
            this.incomeCalList();
        },
        pageFun(e, type) { // 分页
            if (type == "pageSize") {
                this.params.pageSize = e;
            } else {
                this.params.page = e;
            }
            this.incomeCalList()
        },
        async commonDepartAll(id) { // 部门 下拉
            let resData = (await commonDepartAll({}, id)).data;
            this.selectDepart = resData ? resData : [];
        },
        handleClose() {
          this.dialogVisible = false
          this.dialogParams = {
            page: 1,
            pageSize: 10
          }
        },
        pageFun2(e, type) {
          // 分页
          if (type == "pageSize") {
            this.dialogParams.pageSize = e;
          } else {
            this.dialogParams.page = e;
          }
          this.getDialogData();
        },
        openDialog(row){
          this.dialogVisible = true
          this.dialogParams.adminId = row.adminId
          let date = this.params.startDate.split("-")
          this.dialogParams.checkDate = date[0]+'-'+date[1]
          this.getDialogData();
        },
        async getDialogData(){
          let params = {...this.dialogParams}
          this.loading2 = true
          const { data } = await capacityDetail(params)
          this.loading2 = false
          this.dialogData = data 
          this.pagination2.total = data.total
        },
        pageFun3(e, type) {
          // 分页
          if (type == "pageSize") {
            this.dayParams.pageSize = e;
          } else {
            this.dayParams.page = e;
          }
          if(this.dayParams.shiftId){
            this.pageAgain = true
            this.showDay(this.dayParams.shiftId);
          }
          this.changePage = true
          this.pageAgain = false
        },
        async showDay(id) {
          if(id){
            this.dayParams.shiftId = id
          }
          if(this.changePage && !this.pageAgain){
            this.dayParams.page = 1
            this.dayParams.pageSize = 10
          }
          let params = {...this.dayParams}
          this.loading3 = true
          const { data } = await capacityDayDetail(params)
          this.loading3 = false
          if(data.records&&data.records.length){
            this.dayData = data.records
            this.pagination3.total = data.total
          } else {
            this.dayData = []
            this.pagination3.total = 0
          }
        },
        renderHeader(h, { column,$index }) {
            return h('div', [
                h('span', column.label),
                h(
                    'el-tooltip',
                    {
                        props: {
                            effect: 'dark',
                            content:  this.tooltipArr[$index],
                            placement: 'bottom',
                        },
                    },
                    [
                        h('i', {
                            class: 'iconfont icon-wenhao',
                            style: 'color:#909399;margin-left:5px;',
                        }),
                    ],
                ),
            ]);
        },
        clearDayParams(){
          this.dayParams.page = 1
          this.dayParams.pageSize = 10
        }
  }
}
</script>
<style lang="less">
  .hover-table {
  width: 680px;
  font-size: 14px;
  margin: 0 12px 16px;
  border-collapse: collapse;
  .hover-td,
  .hover-th {
    padding: 4px 8px;
    border: 1px solid #eee;
    white-space: pre;
  }
  .hover-td{
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
  }
}
</style>
<style lang="less" scoped>
.incomeCalculation-container {
    text-align: left;
    .calculation-screen-container {
      margin-left: 16px;
        height: 80px;
        display: flex;
        align-items: center;
        .common-input-container {
            margin-bottom: 0;
        }
    }
    .grey-line {
        height: 16px;
        width: 120%;
        margin: 0 0 0 -10%;
        background: #eee;
    }
    .title {
        box-sizing: border-box;
        padding: 6px 16px 22px;
        height: 56px;
        color: #333;
        font-size: 24px;
        line-height: 24px;
        display: flex;
        justify-content: space-between;
        position: relative;
        &::after {
            display: flex;
            content: ' ';
            position: absolute;
            height: 1px;
            width: 120%;
            background: #f2f4f5;
            bottom: 0;
            left: -10%;
        }
    }
}
.table3 {
  width: 880px;
}
</style>